@import './reset.css';
@import 'kitten';

$k-typography: k-typography-definition(
  (
    root: 16px,
    font-size: 1rem,
    font-weight: 400,
    line-height: 1.5rem,
  )
);

@import '@kisskissbankbank/kitten/src/stylesheets/utilities';
